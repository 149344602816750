<template>
  <footer class="footer border-top">
    <div class="container">
      <div class="footer__logos">
        <router-link :to="{ name: 'site_index' }" class="footer__logo">
          <img
            class="footer__logo-img"
            src="@/assets.v2/images/logo.svg"
            alt="Shortform - Book Summary"
          />
        </router-link>
        <div class="apps">
          <a
            class="apps__link"
            href="https://apps.apple.com/us/app/shortform/id1489472007"
            target="_blank"
          >
            <img
              class="apps__img"
              src="@/assets.v2/images/app.svg"
              alt="Download from App Store"
            />
          </a>
          <a
            class="apps__link"
            href="https://play.google.com/store/apps/details?id=com.shortform.app"
            target="_blank"
          >
            <img
              class="apps__img"
              src="@/assets.v2/images/google.svg"
              alt="Download on Google Play"
            />
          </a>
        </div>
      </div>
      <div class="quick-links border-top">
        <div class="quick-links__item quick-links__item--menu">
          <h3 class="quick-links__title">Menu</h3>
          <ul class="footer-menu">
            <li class="footer-menu__item">
              <router-link
                :to="{ name: 'site_index' }"
                class="footer-menu__link"
                >Home</router-link
              >
            </li>
            <li class="footer-menu__item">
              <router-link
                :to="{ name: 'site_summaries' }"
                class="footer-menu__link"
                >Our Content</router-link
              >
            </li>
            <li class="footer-menu__item">
              <router-link
                :to="{ name: 'site_pricing' }"
                class="footer-menu__link"
                >Pricing</router-link
              >
            </li>
            <li class="footer-menu__item">
              <router-link
                :to="{ name: 'site_about' }"
                class="footer-menu__link"
                >About</router-link
              >
            </li>
          </ul>
        </div>
        <div class="quick-links__item quick-links__item--categories">
          <h3 class="quick-links__title">Popular Categories</h3>
          <div class="flex">
            <ul class="footer-menu">
              <li class="footer-menu__item">
                <a
                  class="footer-menu__link"
                  href="https://www.shortform.com/best-books/genre/best-management-books-of-all-time"
                  >Management & Leadership</a
                >
              </li>
              <li class="footer-menu__item">
                <a
                  class="footer-menu__link"
                  href="https://www.shortform.com/best-books/genre/best-business-books-of-all-time"
                  >Business</a
                >
              </li>
              <li class="footer-menu__item">
                <a
                  class="footer-menu__link"
                  href="https://www.shortform.com/best-books/genre/best-entrepreneurship-books-of-all-time"
                  >Entrepreneurship</a
                >
              </li>
              <li class="footer-menu__item">
                <a
                  class="footer-menu__link"
                  href="https://www.shortform.com/best-books/genre/best-money-books-of-all-time"
                  >Money & Finance</a
                >
              </li>
            </ul>
            <ul class="footer-menu">
              <li class="footer-menu__item">
                <a
                  class="footer-menu__link"
                  href="https://www.shortform.com/best-books/genre/best-career-guide-books-of-all-time"
                  >Career & Success</a
                >
              </li>
              <li class="footer-menu__item">
                <a
                  class="footer-menu__link"
                  href="https://www.shortform.com/best-books/genre/best-productivity-books-of-all-time"
                  >Productivity</a
                >
              </li>
              <li class="footer-menu__item">
                <a
                  class="footer-menu__link"
                  href="https://www.shortform.com/best-books/genre/best-health-books-of-all-time"
                  >Health & Well-Being</a
                >
              </li>
              <li class="footer-menu__item">
                <a
                  class="footer-menu__link"
                  href="https://www.shortform.com/best-books/genre/best-communication-books-of-all-time"
                  >Communication</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="quick-links__item quick-links__item--summaries">
          <h3 class="quick-links__title">
            <span>Popular Book Guides</span>
          </h3>
          <ul class="footer-menu">
            <li class="footer-menu__item">
              <a
                class="footer-menu__link"
                href="https://www.shortform.com/summary/the-7-habits-of-highly-effective-people-summary-stephen-covey"
                >The 7 Habits of Highly Effective People</a
              >
            </li>
            <li class="footer-menu__item">
              <a
                class="footer-menu__link"
                href="https://www.shortform.com/summary/rich-dad-poor-dad-summary-robert-kiyosaki"
                >Rich Dad, Poor Dad</a
              >
            </li>
            <li class="footer-menu__item">
              <a
                class="footer-menu__link"
                href="https://www.shortform.com/summary/the-4-hour-workweek-summary-tim-ferriss"
                >The 4-Hour Workweek</a
              >
            </li>
            <li class="footer-menu__item">
              <a
                class="footer-menu__link"
                href="https://www.shortform.com/summary/the-power-of-habit-summary-charles-duhigg"
                >The Power of Habit</a
              >
            </li>
          </ul>
        </div>
        <div class="quick-links__item quick-links__item--contact">
          <h3 class="quick-links__title">Contact Us</h3>
          <ul class="footer-menu">
            <li class="footer-menu__item">
              <a class="footer-menu__link" href="mailto:help@shortform.com">
                <span class="footer-menu__link-img">
                  <img src="@/assets.v2/images/email.svg" alt="" />
                </span>
                <span>help@shortform.com</span>
              </a>
            </li>
            <li class="footer-menu__item">
              <span class="footer-menu__link">
                <span class="footer-menu__link-img">
                  <img src="@/assets.v2/images/map.svg" alt="" />
                </span>
                <span>2027 W. Division St Chicago, IL 60622</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__copyright-social border-top">
        <div class="copyright">
          <p class="copyright__text">
            Copyright © 2021 ShortForm™ | All Rights Reserved |
            <router-link class="copyright__link" :to="{ name: 'site_privacy' }"
              >Privacy</router-link
            >
            |
            <router-link class="copyright__link" :to="{ name: 'site_terms' }"
              >Terms</router-link
            >
          </p>
        </div>
        <div class="social">
          <a
            class="iconfont icon-facebook social__icon"
            href="https://www.facebook.com/shortformhq"
            title="Facebook"
          ></a>
          <a
            class="iconfont icon-twitter social__icon"
            href="https://twitter.com/_shortform"
            title="Twitter"
          ></a>
          <a
            class="iconfont icon-instagram social__icon"
            href="https://www.instagram.com/_shortform"
            title="Instagram"
          ></a>
          <a
            class="iconfont icon-linkedin social__icon"
            href="https://www.linkedin.com/company/shortform-summaries"
            title="LinkedIn"
          ></a>
        </div>
      </div>
    </div>
  </footer>
</template>
