import SiteIndex from './views/Index.vue'

import SiteAbout from './views/SiteAbout.vue'
import SitePricing from './views/Pricing.vue'
import SiteSummaries from './views/SiteSummaries.vue'
import SiteTerms from './views/SiteTerms.vue'
import SiteDeleteAccount from './views/DeleteAccount.vue'
import SiteVideoStartup from './views/VideoStartup.vue'
import SitePrivacy from './views/SitePrivacy.vue'
import PublicPage404 from './views/Page404.vue'
import ExtensionAuth from './views/ExtensionAuth.vue'

import { setLocation } from '@/helpers/routes'

const appTitle = process.env.VUE_APP_TITLE

const NopComponent = {
  template: '<span></span>',
}

const routes = [
  {
    path: '/',
    name: 'site_index',
    component: SiteIndex,
    meta: {
      title: "Shortform - The World's Best Book Summaries",
      metaTags: [
        {
          name: 'description',
          content:
            "Shortform has the world's best book summaries of bestsellers and non-fiction books. Get your free book summary today.",
        },
      ],
    },
  },
  {
    path: '/pricing',
    name: 'site_pricing',
    component: SitePricing,
    meta: {
      title: "Pricing | Shortform - The World's Best Book Summaries",
      metaTags: [
        {
          name: 'description',
          content:
            "Get the world's best book summaries at an affordable price. Access to bestsellers at less than the cost of a book per month!",
        },
      ],
    },
  },
  {
    path: '/about',
    name: 'site_about',
    component: SiteAbout,
    meta: {
      title: "About | Shortform - The World's Best Book Summaries",
      metaTags: [
        {
          name: 'description',
          content:
            "Meet the team behind Shortform, creator of the world's best book summaries. Meet the founder of Shortform.",
        },
      ],
    },
  },
  {
    path: '/terms',
    name: 'site_terms',
    component: SiteTerms,
    meta: {
      title: "Terms of Service | Shortform - The World's Best Book Summaries",
      metaTags: [
        {
          name: 'description',
          content:
            "Find Shortform's Terms of Service here. The world's best book summaries, at an affordable price, with friendly terms.",
        },
      ],
    },
  },
  {
    path: '/delete_account',
    name: 'site_delete_account',
    component: SiteDeleteAccount,
    meta: {
      title: "Delete account | Shortform - The World's Best Book Summaries",
      metaTags: [
        {
          name: 'description',
          content: 'Find out how to delete your Shortform account.',
        },
      ],
    },
  },
  {
    path: '/extension-auth',
    name: 'extension_auth',
    component: ExtensionAuth,
    meta: {},
  },
  {
    path: '/shortform_video',
    name: 'site_video',
    component: SiteVideoStartup,
    meta: {
      title:
        "ShortForm Video Startup History | Shortform - The World's Best Book Summaries",
      metaTags: [
        {
          name: 'description',
          content:
            "Shortform was the leading video website for video jockeys to play custom shows to audiences. Learn more about Shortform's history and its most famous VJs.",
        },
      ],
    },
  },
  {
    path: '/summaries',
    name: 'site_summaries',
    component: SiteSummaries,
    meta: {
      title: "Our Summaries | Shortform - The World's Best Book Summaries",
      metaTags: [
        {
          name: 'description',
          content:
            "Learn what exactly makes Shortform's book summaries the best in the world. Comprehensive, organized, cutting out fluff, and more.",
        },
      ],
    },
  },
  {
    path: '/privacy',
    name: 'site_privacy',
    component: SitePrivacy,
    meta: {
      title: "Privacy Policy | Shortform - The World's Best Book Summaries",
      metaTags: [
        {
          name: 'description',
          content:
            "Find Shortform's Privacy Policy here. The world's best book summaries, at an affordable price, with friendly terms.",
        },
      ],
    },
  },
  {
    path: '/404',
    name: 'public_not_found',
    component: PublicPage404,
    meta: { title: appTitle + ' | Page Not Found' },
  },
  {
    path: '/app/login',
    name: 'login',
    beforeEnter(to: any, from: any, next: any): void {
      // Redirect to transfer from the public pages app to the main app.
      setLocation('/app/login', next)
    },
    component: NopComponent,
  },
  {
    path: '/app/signup',
    name: 'signup',
    beforeEnter(to: any, from: any, next: any): void {
      // Redirect to transfer from the public pages app to the main app.
      // to.fullPath contains query string, we use this to optionally pass the
      // email to the signup form.
      setLocation(to.fullPath, next)
    },
    component: NopComponent,
  },
  {
    path: '/app',
    name: 'books',
    beforeEnter(to: any, from: any, next: any): void {
      setLocation('/app', next)
    },
    component: NopComponent,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'public_not_found' },
  },
]

export default routes
