
import { Vue } from 'vue-class-component'
import { Options, Prop } from 'vue-property-decorator'

import Navbar from '@/components/public/Navbar.vue'
import WhyPeopleLove from '@/components/public/WhyPeopleLove.vue'
import SignupWithEmailForm from '@/components/public/SignupWithEmailForm.vue'
import Footer from '@/components/public/Footer.vue'
import PublicBookCard from '@/components/public/PublicBookCard.vue'

@Options({
  components: {
    Navbar,
    WhyPeopleLove,
    SignupWithEmailForm,
    Footer,
    PublicBookCard,
  },
})
export default class SitePricing extends Vue {
  @Prop({ default: 2400 }) private monthlyPriceValue!: number
  @Prop({ default: 19700 }) private annualPriceValue!: number

  get monthlyPrice(): string {
    return `$${(this.monthlyPriceValue / 100).toFixed(2)}/mo`
  }

  get annualPrice(): string {
    return `$${(this.annualPriceValue / 100 / 12).toFixed(2)}/mo`
  }
}
