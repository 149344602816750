<template>
  <div class="panel-section">
    <div class="container">
      <div class="panel panel--background-grey panel-third text-center">
        <div class="panel__inner">
          <h2 class="panel__title">Try Shortform for free</h2>
          <div class="panel__text">
            <p>
              <slot>
                Learn the key points of a book in 30 minutes or less.
              </slot>
            </p>
          </div>
          <router-link
            :to="{ name: 'signup' }"
            class="button button--theme button--size-m"
            >Sign up for free</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets.v2/css/custom/variables';
@import '@/assets.v2/css/custom/mixins';
@import '@/assets.v2/css/custom/panel';

.panel-section {
  margin-bottom: 115px;
  @media (max-width: $lg-up) {
    margin-bottom: 100px;
  }
  .panel {
    border-radius: 24px;
    padding-top: 70px;
    @media (max-width: $md-up) {
      padding: 75px 50px 79px;
    }
    @media (max-width: $sm-up) {
      padding: 53px 20px 51px;
    }
  }
  .panel__title {
    margin-bottom: 10px;
    @media (max-width: $xs-up) {
      margin-bottom: 2px;
    }
  }
  .panel__text {
    @media (max-width: $sm-up) {
      margin-left: 0;
    }
  }
  .button {
    margin-top: 20px;
    @media (max-width: $md-up) {
      margin-top: 17px;
    }
    @media (max-width: $xs-up) {
      margin-top: 22px;
      max-width: 311px;
    }
  }
  &--other-margin {
    margin-bottom: 100px;
    @media (max-width: $md-up) {
      margin-bottom: 73px;
    }
  }
}
</style>
