<template>
  <div class="best">
    <div class="container">
      <div class="best__text">
        <h2 class="best__title">
          <span>Why are Shortform</span>
          <span><br /></span>
          <span>guides <span class="highlight">the best</span>?</span>
        </h2>
        <p>You get more ideas and insights than anywhere else.</p>
      </div>
      <div class="best__list">
        <div class="best__item">
          <div class="best__icon">
            <img src="@/assets.v2/images/best-3.svg" alt="" />
          </div>
          <h3 class="h4">Crystal-Clear Logic</h3>
          <p class="text-left">
            We take confusing ideas and explain them in plain and simple ways.
            Never get confused by a complicated book again.
          </p>
        </div>
        <div class="best__item">
          <div class="best__icon">
            <img src="@/assets.v2/images/best-2.svg" alt="" />
          </div>
          <h3 class="h4">Brilliant New Insights</h3>
          <p class="text-left">
            We add smart analysis, connecting ideas in novel ways and discussing
            key updates since the book was published.
          </p>
        </div>
        <div class="best__item">
          <div class="best__icon">
            <img src="@/assets.v2/images/best-1.svg" alt="" />
          </div>
          <h3 class="h4">Always Concise</h3>
          <p class="text-left">
            Your time is valuable, and we don't waste it. We write with zero
            fluff, making every word and sentence deserve your time.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets.v2/css/custom/variables';
@import '@/assets.v2/css/custom/the-best';
</style>
