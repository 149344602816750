<template>
  <div id="app" class="sf-app-site-v2">
    <div id="iosFix"></div>
    <router-view />
  </div>
</template>

<style lang="scss">
@import '@/assets.v2/css/custom/variables';
@import '@/assets.v2/css/custom/mixins';
@import '@/assets.v2/css/custom/banner';
@import '@/assets.v2/css/custom/the-best';
@import '@/assets.v2/css/custom/mobile-apps';
@import '@/assets.v2/css/custom/book-summaries-list';
</style>
